<template>
    <b-col xl="8">
        <mail-preferences-card/>
    </b-col>
</template>

<script>
    const MailPreferencesCard = () => import('@/components/MailPreferencesCard');

    export default {
        name: "UserSettings",
        components: {MailPreferencesCard}
    }
</script>
